import { AppOperations, EndPoints } from "../Constants/Enums"
import Cookies from "js-cookie"

async function getUserOrders() {
    try {
        const operationId = AppOperations.LIST_USER_ORDERS
        const userID = JSON.parse(Cookies.get("userCookie")).uid
        const requestBody = { operationId, userID }
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
        }
        const response = await fetch(EndPoints.AppOperationsLambda, options)
        const jsonResponse = await response.json()
        return jsonResponse
    } catch (error) {
        console.error("Error getting user orders :", error)
        throw error
    }
}

export default getUserOrders
