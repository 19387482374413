import { AppOperations, EndPoints } from "../Constants/Enums"
import Cookies from "js-cookie"

async function updateCart(cartID, conceptID, version, newOrderedItems, totalPrice) {
    try {
        const operationId = AppOperations.UPDATE_CART
        const userID = JSON.parse(Cookies.get("userCookie")).uid
        const requestBody = {
            operationId,
            conceptID,
            cartID,
            version,
            userID,
            newOrderedItems,
            totalPrice,
        }
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
        }
        const response = await fetch(EndPoints.AppOperationsLambda, options)
        const jsonResponse = await response.json()
        return jsonResponse
    } catch (error) {
        console.error("Error updating Cart :", error)
        throw error
    }
}

export default updateCart
