import { AppOperations, EndPoints } from "../Constants/Enums";

export async function getSubCategories() {
  try {
    const activeRotation = await getActiveMenuRotation();

    const operationId = AppOperations.LIST_SUB_CATEGORIES;
    const requestBody = {
      operationId,
      menuRotationID: activeRotation ? activeRotation.id : "",
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };
    const response = await fetch(EndPoints.AppOperationsLambda, options);
    let subCategoriesList = await response.json();

    // Sort Categories by   
    subCategoriesList = subCategoriesList.sort((a, b) => {
      return Number(a.precedence) - Number(b.precedence);
    });

    return subCategoriesList;
  } catch (error) {
    console.error("Error getting Sub Categories :", error);
    throw error;
  }
}

export async function getActiveMenuRotation() {
  try {
    const operationId = AppOperations.LIST_ACTIVE_ROTATIONS;
    const requestBody = { operationId };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };
    const response = await fetch(EndPoints.AppOperationsLambda, options);
    const listOfActiveRotations = await response.json();

    if (listOfActiveRotations && listOfActiveRotations.length > 0) {
      return listOfActiveRotations[0];
    }

    return null;
  } catch (error) {
    console.error("Error getting Menu Rotations :", error);
    throw error;
  }
}
