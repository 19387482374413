import { AppOperations, EndPoints } from "../Constants/Enums";

async function getMainCategories() {
  try {
    const operationId = AppOperations.LIST_MAIN_CATEGORIES;
    const requestBody = { operationId };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };
    const response = await fetch(EndPoints.AppOperationsLambda, options);
    let mainCategoriesList = await response.json();

    // Sort Categories by Precedence
    mainCategoriesList = mainCategoriesList.sort((a, b) => {
      return Number(a.precedence) - Number(b.precedence);
    });

    return mainCategoriesList;
  } catch (error) {
    console.error("Error getting Main Categories :", error);
    throw error;
  }
}

export default getMainCategories;
