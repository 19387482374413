// Env: prod
export const EndPoints = {
  // AppOperations-prod
  AppOperationsLambda:
    "https://qe5luavs5b4ydyscddsy4vcmci0kwhma.lambda-url.us-east-2.on.aws/",
  // mobileAppPN-prod
  pushNotification:
    "https://q4a7rg3uimxs3ew5jffef5mi3e0uefnb.lambda-url.us-east-2.on.aws/",
  image: "https://res.cloudinary.com/dipqm0n1i/image/upload/EtihadAirways/",
};

export const AppOperations = {
  // Main Menu Operations
  LIST_MAIN_CATEGORIES: 7,
  LIST_SUB_CATEGORIES: 8,
  LIST_CATEGORIES: 1,
  LIST_MENU_ITEMS: 3,
  // General Operations
  CREATE_REVIEW: 9,
  VALIDATE_TABLE: 10,
  // Cart Operations
  CREATE_CART: 12,
  GET_CART: 2,
  UPDATE_CART: 5,
  // Waiter Operations
  CALL_WAITER: 13,
  UPDATE_CALL_WAITER: 14,
  GET_CALL_WAITER: 15,
  // Concepts Operations
  GET_CONCEPT: 11,
  LIST_CONCEPTS: 16,
  // Order Operations
  LIST_USER_ORDERS: 6,
  CREATE_ORDER: 4,
  CANCEL_ONLINE_ORDER: 17,
  // Menu Rotation Operations
  LIST_MENU_ROTATIONS: "listMenuRotations",
  LIST_ACTIVE_ROTATIONS: "listActiveRotations",
};

export const bucketURL =
  "https://etihadairwaysassetsd26b2-prod.s3.us-east-2.amazonaws.com/public/";

export const Pages = {
  MENU_LIST: "menuList",
  ORDERS: "myorders",
  ORDER_DETAILS: "orderdetails",
  TRACK_ORDER: "trackOrder",

  CART: "cart",
  LANGUAGES: "languages",
  REVIEW: "review",
  LANG: "lang",
};

export const Keys = {
  CONCEPT: "Concept",
  MAIN_CATEGORIES: "MainCategories",
  SUB_CATEGORIES: "SubCategories",
  CATEGORIES: "Categories",
  CART: "Cart",
  CONCEPTS: "Concepts",
};

export const OrderStatus = {
  pending: "pending",
  sent: "sent to restaurant",
  failed: "failed to sent to restaurant",
  confirmed: "confirmed",
  cancelled: "cancelled",
  pendingCancellation: "pending Cancellation",
  updated: "updated",
  acceptedByWaiter: "accepted by waiter",
  sentToKitchen: "sent To Kitchen",
  acceptedByKitchen: "accepted by kitchen",
  ready: "ready",
  allReady: "all ready",
  outForDelivery: "out for delivery",
  delivered: "delivered",
};

export const WaiterCallingStatus = {
  pending: "pending",
  arrived: "arrived",
};

export const OrderType = "Dine In";
export const MySecretKey = "MySecretKey";

// Etihad Static PDFs/Websites
export const LOUNGE_MAP_PDF = "PDFs/Lounge+Map+v1.1+0724%5B47%5D_rotated.pdf";

export const LOYALTY_PDF = "PDFs/LOYALTY+PROGRAMME+0224.pdf";
export const LOYALTY_LINK =
  "https://www.etihadguest.com/en/quick-enrolment.html";
