import { AppOperations, EndPoints } from "../Constants/Enums"

async function createOnlineOrder(
    cartID,
    conceptID,
    userID,
    userName,
    orderedItems,
    specialRequest,
    OrderStatus,
    orderType,
    orderTime,
    totalPrice,
    tableID,
    createdAt,
    tableName,
    parentOrder,
    childrenStatus
) {
    try {
        const operationId = AppOperations.CREATE_ORDER
        const requestBody = {
            operationId,
            cartID,
            conceptID,
            userID,
            userName,
            orderedItems,
            specialRequest,
            OrderStatus,
            orderType,
            orderTime,
            totalPrice,
            tableID,
            createdAt,
            tableName,
            parentOrder,
            childrenStatus,
        }
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
        }
        const response = await fetch(EndPoints.AppOperationsLambda, options)
        const jsonResponse = await response.json()
        // alert('Thank you, Your order is created successfully')
        return jsonResponse
    } catch (error) {
        console.error("Error creating online order :", error)
        throw error
    }
}

export default createOnlineOrder
