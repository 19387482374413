import { AppOperations, EndPoints } from "../Constants/Enums";

async function getCategoryData(subCategoryID) {
  try {
    const operationId = AppOperations.LIST_CATEGORIES;
    const requestBody = { operationId, subCategoryID };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };
    const response = await fetch(EndPoints.AppOperationsLambda, options);
    let categoriesList = await response.json();

    // Sort Categories by Precedence
    categoriesList = categoriesList.sort((a, b) => {
      return Number(a.precedence) - Number(b.precedence);
    });

    return categoriesList;
  } catch (error) {
    console.error("Error getting category data :", error);
    throw error;
  }
}

export default getCategoryData;
